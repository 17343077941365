import React from "react";

const Youtube = ({ color }) => {
  return (
    <svg
      id="Group_355"
      data-name="Group 355"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_471"
        data-name="Path 471"
        d="M23.811,615.861a11.906,11.906,0,1,1-11.906-11.905,11.905,11.905,0,0,1,11.906,11.905"
        transform="translate(0 -603.956)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_472"
        data-name="Path 472"
        d="M89.762,730.613c1.426-.114,3.108-.171,4.391-.171s2.937.057,4.391.171c1.682.143,2.538.428,2.766,2.11a21.278,21.278,0,0,1,.171,2.908,20.987,20.987,0,0,1-.171,2.908c-.228,1.711-1.083,1.967-2.766,2.11-1.454.143-3.108.171-4.391.171s-2.965-.029-4.391-.171c-1.682-.143-2.566-.4-2.794-2.11a27.454,27.454,0,0,1-.143-2.908,27.869,27.869,0,0,1,.143-2.908c.228-1.682,1.112-1.967,2.794-2.11"
        transform="translate(-82.227 -723.745)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_473"
        data-name="Path 473"
        d="M197.209,786.98l3.821,2.2-3.821,2.224Z"
        transform="translate(-186.766 -777.289)"
        fill={color}
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Youtube;
