import React from "react";

const DownArrow = ({ primary }) => {
  return (
    <>
    <style>
      {
        `
        .dw > path {
          stroke: ${primary};
        }`
      }
    </style>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11px"
      height="7px"
      viewBox="0 0 11.44 7.134"
      className="dw"
    >
      <path
        id="arrow"
        d="M325.844,1057.273l-5.013,5.013-5.013-5.013"
        transform="translate(-315.112 -1056.565)"
        fill="none"
        stroke-miterlimit="10"
        stroke-width="2"
      ></path>
    </svg>
    </>
  );
};

export default DownArrow;
