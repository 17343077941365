import React from "react";

const Tiktok = ({ color }) => {
  return (
    <svg
      id="Group_351"
      data-name="Group 351"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_467"
        data-name="Path 467"
        d="M1967.932,11.9A11.906,11.906,0,1,1,1956.027,0,11.905,11.905,0,0,1,1967.932,11.9"
        transform="translate(-1944.121 0.002)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_468"
        data-name="Path 468"
        d="M2062.011,90.628a3.107,3.107,0,0,1-1.9-1.1A3.651,3.651,0,0,1,2059.15,87h-2.628V97.479a2.236,2.236,0,0,1-2.262,2.229,2.316,2.316,0,0,1-1.83-.931,2.247,2.247,0,0,1,1.83-3.526,2.145,2.145,0,0,1,.665.1V92.69a4.094,4.094,0,0,0-.665-.067h-.1a4.849,4.849,0,0,0-3.459,8.149,4.949,4.949,0,0,0,3.559,1.53,4.862,4.862,0,0,0,4.89-4.823V92.025a4.843,4.843,0,0,0,3.626,1.231V90.628a1.49,1.49,0,0,1-.765,0"
        transform="translate(-2043.829 -82.396)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Tiktok;
