import React from "react";

const Linkedin = ({ color, handleClick }) => {
  return (
    <>
      <style>
        {`
        #Path_469 {
          fill: ${color};
        }`}
      </style>
      <svg
        id="Group_352"
        data-name="Group 352"
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 23.811 23.81"
        className="linked"
        onClick={() => handleClick()}
      >
        <path
          id="Path_469"
          data-name="Path 469"
          d="M1967.932,615.861a11.906,11.906,0,1,1-11.906-11.905,11.905,11.905,0,0,1,11.906,11.905"
          transform="translate(-1944.121 -603.956)"
          fill-rule="evenodd"
        />
        <path
          id="Path_470"
          data-name="Path 470"
          d="M2039.179,701.968h2.681v9h-2.681Zm1.324-4.568a1.622,1.622,0,1,1,0,3.244,1.622,1.622,0,0,1,0-3.244m2.946,4.568h2.648v1.258a3.064,3.064,0,0,1,2.549-1.357h.729a3.058,3.058,0,0,1,3.045,3.045v6.057h-2.648v-4.866a1.76,1.76,0,0,0-1.754-1.82,1.9,1.9,0,0,0-1.92,1.82v4.866h-2.648Z"
          transform="translate(-2033.895 -692.452)"
          fill="#fff"
          fill-rule="evenodd"
        />
      </svg>
    </>
  );
};

export default Linkedin;
