import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import MagnifyingGlass from "../FilterColumn/MagnifyingGlass";
import Close from "../FilterColumn/Close";
import { theme } from "../../utils/global-styles/theme";
import { SET_SEARCH_TERM } from "../../state/constants/meetTheFoundry";
import { useDispatch } from "react-redux";

const FoundrySearchBar = ({ setSkip, setSelected }) => {
  const Wrapper = styled.div`
      width: 80%;
      float: right;
      margin-right: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        float: none;
        padding-top: 30px;
        margin: 0 auto;
      }
    `,
    Container = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      border: 2px solid #b4b4b4;
      align-items: baseline;
      padding: 5px;
    `,
    StyledSearchInput = styled("input")`
      border: none;
      width: 70%;
      font-size: 15px;
      font-family: ${theme.fontFaces.paragraphs};
      font-weight: bold;

      &:focus {
        outline: none;
      }
    `,
    SearchIconWrapper = styled.div`
      margin-right: 5%;
    `,
    CloseIconWrapper = styled.div`
      position: absolute;
      right: 35px;
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        // mobile only
        right: 13%;
      }
    `;

  const { register, reset } = useForm();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Container>
        <SearchIconWrapper>
          <MagnifyingGlass primary={theme.colors.black} />
        </SearchIconWrapper>
        <StyledSearchInput
          id="search-catalog"
          className={"foundry-search main pale-white-bg"}
          name="search"
          {...register("search", {
            onChange: e => {
              setSkip(0);
              setSelected(0);
              dispatch({ type: SET_SEARCH_TERM, payload: e.target.value });
            },
          })}
          type="text"
          placeholder={"Search"}
        />
        <CloseIconWrapper
          onClick={() => {
            reset({ search: "" });
            dispatch({ type: SET_SEARCH_TERM, payload: "" });
          }}
        >
          <Close primary={theme.colors.black} />
        </CloseIconWrapper>
      </Container>
    </Wrapper>
  );
};

export default FoundrySearchBar;
