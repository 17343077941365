import React from "react";

const Telegram = ({ color }) => {
  return (
    <svg
      id="Group_354"
      data-name="Group 354"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_476"
        data-name="Path 476"
        d="M671.7,615.861A11.906,11.906,0,1,1,659.8,603.956,11.905,11.905,0,0,1,671.7,615.861"
        transform="translate(-647.893 -603.956)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_477"
        data-name="Path 477"
        d="M723.2,729.037c3.275-1.373,7.237-3.328,10.67-4.648,3.169-1.162,3.117-.845,2.641,2.324-.37,2.535-.792,5.071-1.215,7.606-.158,1.215-.792,1.849-2.113,1.109l-4.12-2.905c-.581-.475-.422-.845.053-1.32l3.8-3.592c1-1,.528-1.373-.634-.581l-5.229,3.539a2.536,2.536,0,0,1-2.377.264l-1.8-.634c-1.162-.423-.264-.9.317-1.162"
        transform="translate(-718.373 -717.426)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Telegram;
