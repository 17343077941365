import React from "react";

const Whatsapp = ({ color }) => {
  return (
    <svg
      id="Group_353"
      data-name="Group 353"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_465"
        data-name="Path 465"
        d="M1319.6,615.861a11.906,11.906,0,1,1-11.906-11.905,11.905,11.905,0,0,1,11.906,11.905"
        transform="translate(-1295.785 -603.956)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_466"
        data-name="Path 466"
        d="M1383.245,690.651c.421-.268.688-.076.841.344l.382.994s.268.535-.421,1.07c-.115.076-.115.076-.077.268a5.5,5.5,0,0,0,2.715,2.905c.191.115.229.115.421-.038l.5-.5c.344-.306.535-.421.841-.191.229.153.459.344.688.5.229.191.612.421.229.879l-.344.421a1.307,1.307,0,0,1-1.568.5,7.189,7.189,0,0,1-5.123-4.893,1.915,1.915,0,0,1,.917-2.256m2.676-4.091a7.531,7.531,0,1,1,0,15.063,7.279,7.279,0,0,1-3.173-.688l-4.014.688.535-3.976-.038-.076a7.548,7.548,0,0,1,6.691-11.01m-4.741,13.381,1.07-.191.65-.115a6.218,6.218,0,0,0,3.02.765,6.308,6.308,0,1,0-6.308-6.308,6.448,6.448,0,0,0,.994,3.4l-.076.573-.153,1.147-.115.879Z"
        transform="translate(-1374.016 -682.186)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Whatsapp;
