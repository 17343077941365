import React from "react";

const Twitter = ({ color }) => {
  return (
    <svg
      id="Group_350"
      data-name="Group 350"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_474"
        data-name="Path 474"
        d="M1319.6,11.9A11.906,11.906,0,1,1,1307.691,0,11.905,11.905,0,0,1,1319.6,11.9"
        transform="translate(-1295.785 0.002)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_475"
        data-name="Path 475"
        d="M1393.019,119.011a3.1,3.1,0,0,1,5.319-2.7,7.829,7.829,0,0,0,1.838-.821,3.382,3.382,0,0,1-1.369,1.721,5.827,5.827,0,0,0,1.682-.469,3.267,3.267,0,0,1-1.447,1.447c.509,7.078-7.117,11.38-13.3,7.86,0,0,3.05.156,4.341-1.33a2.974,2.974,0,0,1-2.816-2.112,1.886,1.886,0,0,0,1.408-.078,3.052,3.052,0,0,1-2.386-2.972,1.927,1.927,0,0,0,1.486.352,3,3,0,0,1-1.056-4.067s2.581,3.285,6.3,3.167"
        transform="translate(-1380.981 -109.222)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Twitter;
