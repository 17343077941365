import React from "react";

const Facebook = ({ color }) => {
  return (
    <svg
      id="Group_348"
      data-name="Group 348"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_461"
        data-name="Path 461"
        d="M23.811,11.9A11.906,11.906,0,1,1,11.906,0,11.905,11.905,0,0,1,23.811,11.9"
        transform="translate(0 0.002)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_462"
        data-name="Path 462"
        d="M133.841,94.657h3.248V87.165h2.4l.48-3.026h-2.879V81.851a1.459,1.459,0,0,1,1.587-1.329h1.4v-2.51l-2.51-.111c-2.4-.148-3.728,1.735-3.728,3.986v2.251H131.11v3.026h2.731Z"
        transform="translate(-124.167 -73.769)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Facebook;
