import React from "react";

const Instagram = ({ color }) => {
  return (
    <svg
      id="Group_349"
      data-name="Group 349"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 23.811 23.81"
    >
      <path
        id="Path_463"
        data-name="Path 463"
        d="M671.7,11.9A11.906,11.906,0,1,1,659.8,0,11.905,11.905,0,0,1,671.7,11.9"
        transform="translate(-647.893 0.002)"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        id="Path_464"
        data-name="Path 464"
        d="M733.268,81.616h7a4.119,4.119,0,0,1,4.1,4.1v6.967a4.119,4.119,0,0,1-4.1,4.1h-7a4.12,4.12,0,0,1-4.1-4.1V85.716a4.12,4.12,0,0,1,4.1-4.1m7.584,2.649h0a.871.871,0,1,1,0,1.742.871.871,0,1,1,0-1.742m-4.1.8h.036a4.137,4.137,0,1,1,0,8.273h-.036a4.137,4.137,0,0,1,0-8.273m0,1.415h.036a2.74,2.74,0,0,1,0,5.479h-.036a2.74,2.74,0,0,1,0-5.479M733.3,82.922h6.931a2.834,2.834,0,0,1,2.83,2.83v6.894a2.834,2.834,0,0,1-2.83,2.83H733.3a2.834,2.834,0,0,1-2.83-2.83V85.753a2.834,2.834,0,0,1,2.83-2.83"
        transform="translate(-724.863 -77.294)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Instagram;
